import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RxHamburgerMenu, RxCross1 } from 'react-icons/rx';

const Header = ({ isHomePage = false, setIsMenuOpen }) => {
	const [isSticky, setIsSticky] = useState(false);
	const [isMenuOpen, setIsMenuOpenLocal] = useState(false);

	useEffect(() => {
		if (isHomePage) {
			const handleScroll = () => {
				setIsSticky(window.scrollY > 0);
			};

			window.addEventListener('scroll', handleScroll);

			return () => {
				window.removeEventListener('scroll', handleScroll);
			};
		}
	}, [isHomePage]);

	// Toggle menu function
	const toggleMenu = () => {
		// setIsMenuOpen(!isMenuOpen);
		setIsMenuOpenLocal(!isMenuOpen);
	};

	return (
		<header
			className={`
        w-full transition-all duration-300 
        ${
			isHomePage
				? isSticky
					? 'fixed z-[20] top-0 bg-dark-background'
					: 'absolute z-[20] top-0 bg-transparent'
				: 'relative top-0 bg-dark-background'
		}
      `}
		>
			<div className='flex items-center justify-between pb-4 pt-14 px-3 md:px-16'>
				<Link to='/' className='ml-[10px] md:ml-[7px]'>
					<img
						className='w-[100.4057px] h-[55px] md:w-[140.4057px] md:h-[70px] ml-[10px] md:ml-[7px]'
						src='https://res.cloudinary.com/dzaz9bsnw/image/upload/v1719729396/Be_Athah_x_Final_Logo-03_qethwg.png'
						alt='logo'
					/>
				</Link>
				<ul className='flex gap-10 items-center'>
					<li className='hidden sm:block text-[#d5c4b9] lg:text-[15px] font-urbanist font-normal tracking-[2px]'>
						Science
					</li>
					<Link to='/CalmTest'>
						<li className='hidden sm:block text-[#d5c4b9] lg:text-[15px] font-urbanist font-normal tracking-[2px]'>
							Take the calm test
						</li>
					</Link>
					<li>
						<div
							className='border border-[#d5c4b9] rounded-full mr-9 md:mr-[15px] p-2 w-[36px] h-[36px] md:w-[45px] md:h-[45px] flex items-center justify-center cursor-pointer z-[30]'
							onClick={toggleMenu}
						>
							{/* {isMenuOpen ? (
								<RxCross1 className='w-4 h-4 md:w-5 md:h-5 z-20' color='#d5c4b9' />
							) : (
								<RxHamburgerMenu
									className='w-4 h-4 md:w-5 md:h-5'
									color='#d5c4b9'
								/>
							)} */}
							<RxHamburgerMenu
									className='w-4 h-4 md:w-5 md:h-5'
									color='#d5c4b9'
								/>
						</div>
					</li>
				</ul>
			</div>

			{/* Mobile Menu */}
			{/* <div
				className={`
					fixed inset-0 bg-[#515740] text-[#d5c4b9] 
					transform transition-transform duration-300
					${isMenuOpen ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}
				`}
			>
				<ul className='flex flex-col items-center justify-center h-screen space-y-8'>
					<li>
						<Link
							to='/'
							className='text-sm lg:text-[15px] font-urbanist font-normal tracking-[2px]'
							onClick={toggleMenu}
						>
							Home
						</Link>
					</li>
					<li>
						<Link
							// to='/Science'
							className='text-sm lg:text-[15px] font-urbanist font-normal tracking-[2px]'
							onClick={toggleMenu}
						>
							Science
						</Link>
					</li>
					<li>
						<Link
							to='/CalmTest'
							className='text-sm lg:text-[15px] font-urbanist font-normal tracking-[2px]'
							onClick={toggleMenu}
						>
							Take the Calm Test
						</Link>
					</li>
				</ul>
			</div> */}
		</header>
	);
};

export default Header;