import Axios from 'axios';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Link, useNavigate } from 'react-router-dom';
import { HiArrowLongUp } from 'react-icons/hi2';
import React, { useRef, useEffect, useState } from 'react';

import videoUrl from '../Body/Loader.gif'
const LoadingSpinner = () => (
	// <div className='flex justify-center items-center bg-[#515740] h-screen'>
	// 	<div className='animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-[#d5c4b9]'></div>
	// </div>
	<div className='flex justify-center items-center bg-[#515740] h-screen'>
	<img
	  src={videoUrl} // Set the correct path to your video
	   // Adjust the width and height if needed
	/>
  </div>
);

const Body = () => {
	const sectionRefs = useRef([]);
	const navigate = useNavigate();
	const [content, setContent] = useState([]);
	const [fadeIn, setFadeIn] = useState(false);
	const [loading, setLoading] = useState(true);
	const [isMenuOpen, setIsMenuOpen] = useState(false); // New state for tracking the menu
	const [currentSection, setCurrentSection] = useState(0); // Track the current section index


	useEffect(() => {
		const Request = {
			url: process.env.REACT_APP_BACKEND + 'content',
			method: 'GET',
		};

		(async function () {
			setLoading(true);
			const res = await Axios(Request);
			setContent(res.data);
			setLoading(false);
			setTimeout(() => setFadeIn(true), 5);
		})();
	}, []);

	// IntersectionObserver to track the current section
	// useEffect(() => {
	// 	const observer = new IntersectionObserver(
	// 		(entries) => {
	// 			entries.forEach((entry) => {
	// 				if (entry.isIntersecting) {
	// 					const index = sectionRefs.current.indexOf(entry.target);
	// 					setCurrentSection(index);
	// 				}
	// 			});
	// 		},
	// 		{
	// 			root: null, // Use the viewport as the container
	// 			rootMargin: '0px',
	// 			threshold: 0.5, // Trigger when 50% of the section is visible
	// 		}
	// 	);

	// 	// Observe all sections
	// 	sectionRefs.current.forEach((section) => {
	// 		if (section) observer.observe(section);
	// 	});

	// 	// Cleanup the observer on component unmount
	// 	return () => {
	// 		sectionRefs.current.forEach((section) => {
	// 			if (section) observer.unobserve(section);
	// 		});
	// 	};
	// }, [content]); // Re-run this effect when content changes






	useEffect(() => {
		const observer = new IntersectionObserver(
		  (entries) => {
			entries.forEach((entry) => {
			  if (entry.isIntersecting) {
				const index = sectionRefs.current.indexOf(entry.target);
				console.log('Visible section index:', index);
				setCurrentSection(index);
			  }
			});
		  },
		  {
			root: null,
			rootMargin: '0px',
			threshold: [0.5, 0.75], // You can adjust these values
		  }
		);
	  
		sectionRefs.current.forEach((section) => {
		  if (section) observer.observe(section);
		});
	  
		return () => {
		  sectionRefs.current.forEach((section) => {
			if (section) observer.unobserve(section);
		  });
		};
	  }, [content]);
	  

	  useEffect(() => {
		const sectionId = window.location.hash.replace('#section', '');
		if (sectionId && sectionRefs.current[sectionId]) {
		  sectionRefs.current[sectionId].scrollIntoView({ behavior: 'smooth' });
		}
	  }, []);


	//   useEffect(() => {
		// sectionRefs.current = sectionRefs.current.slice(0, content.length);
	//   }, [content]);






	const handleButtonClick = (index, route) => {
		if (route) {
			navigate('/' + route);
		} else if (sectionRefs.current[index]) {
			sectionRefs.current[index].scrollIntoView({ behavior: 'smooth' });
		}
	};

	// const handleScrollUpClick = () => {
	// 	console.log(currentSection,'currentSection')
	// 	if (currentSection > 0) {
	// 		const previousSectionIndex = currentSection - 1;
	// 		console.log(previousSectionIndex,'pre')
	// 		sectionRefs.current[previousSectionIndex].scrollIntoView({ behavior: 'smooth' });
	// 		setCurrentSection(previousSectionIndex); // Update the current section
	// 	}
	// };
	const handleScrollUpClick = () => {
		if (currentSection > 0) {
			console.log("currentSection",currentSection)
			const previousSectionIndex = currentSection-1;
			console.log("Scrolling to section:", previousSectionIndex);
			sectionRefs.current[previousSectionIndex].scrollIntoView({ behavior: 'smooth' });
		}
	};



	if (loading) {
		return <LoadingSpinner />;
	} else if (content.length === 0) {
		return <div>No Content Found</div>;
	}

	console.log(sectionRefs.current);
	
	return (
		<div
			// className={`transition-opacity duration-500 ${
			// 	fadeIn ? 'opacity-100' : 'opacity-20'
			// } froscrolling`}
			className='h-screen scroll-snap-y snap-mandatory '
		>
			<Header isHomePage={true} setIsMenuOpen={setIsMenuOpen} /> {/* Pass setIsMenuOpen to Header */}
			<div >
				{content.map((item, index) => (
					<div id={`section${index}`}
						key={index}
						ref={(el) => (sectionRefs.current[index] = el)}
						className='sticky   h-screen  scroll-snap-align-start snap-stop-always overflow-hidden'
						
						style={{
							zIndex: index + 1,
						}}
					>
						{/* First Div */}
						<div
							className='absolute inset-0 imageSet scrollingli bg-cover bg-center'
							style={{
								backgroundImage: `url(${item.backgroundImage})`,
								backgroundColor:
									item.backgroundColor || 'transparent',
							}}
						/>
						{/* Second Div */}
						<div className='relative   scrollingli z-10 flex flex-col justify-center items-start h-full text-white p-12 md:p-24 lg:p-24 md:pt-44 lg:pt-44'>
							<div>
								<p
									className={`font-thin font-playfair text-4xl md:text-6xl w-80 md:w-[625px] lg:w-[825px] leading-tight md:leading-none mb-10 tracking-widest text-[#d5c4b9]`}
								>
									{item.title}
								</p>
							</div>
							<div className='mb-8 md:mb-8 text-start md:max-w-5xl'>
								{item.description.map((text, idx) => (
									<p
										key={idx}
										className='mb-3 text-[#d5c4b9] font-urbanist text-[13px] md:text-[17px] w-[300px] md:w-[625px] lg:w-[900px] font-normal tracking-[2px] leading-6 md:leading-9 [word-spacing:1px]'
									>
										{text}
									</p>
								))}
							</div>
							<button
								onClick={() =>
									item.route
										? handleButtonClick(null, item.route)
										: handleButtonClick(item.scrollToIndex)
								}
								className='px-1 sm:px:2 md:px-6 text-[#d5c4b9] py-2 md:py-3 bg-transparent flex justify-center items-center border-[#d5c4b9] border md:border font-urbanist tracking-[2px] border-solid rounded-lg text-sm md:text-base font-extralight md:font-normal'
							>
								{item.button}
							</button>
							{item.chakraIcons?.length > 0 && (
								<div className='flex flex-wrap justify-start items-center mt-8 -mx-4 overflow-hidden'>
									{item.chakraIcons.map((icon, idx) => (
										<div key={idx} className='p-1 md:p-2'>
											<img
												src={icon}
												alt={`Chakra ${idx + 1}`}
												className='w-10 h-10 md:w-16 md:h-16 object-contain'
											/>
										</div>
									))}
								</div>
							)}
						</div>

						<Link  to={`#section${currentSection}`}
							className='fixed bottom-5 w-9 h-9 md:w-12 md:h-12 right-2 flex items-center justify-center bg-transparent border rounded-full border-solid text-[#d5c4b9] border-[#d5c4b9] z-50 mr-[36px] md:mr-[70px] md:mb-6'
							onClick={handleScrollUpClick}
						>
							<HiArrowLongUp
								className='w-6 h-6 md:w-8 md:h-12'
								color='#d5c4b9'
							/>
						</Link>

					</div>


				))}

			</div>

			<div id='section4' ref={(el) => (sectionRefs.current[content.length] = el)}  className='scroll-snap-align-start'>
			<Footer />
			</div>
		</div>
	);
};

export default Body;