import Axios from 'axios';
import { Link } from 'react-router-dom'
import Header from '../Header/Header';
import { motion, AnimatePresence } from 'framer-motion';
import React, { useState, useCallback, useEffect } from 'react';

import {
	BsEmojiLaughing,
	BsEmojiSmile,
	BsEmojiNeutral,
	BsEmojiFrown,
} from 'react-icons/bs';
import { IoIosMail } from 'react-icons/io';
import { FaWhatsapp } from 'react-icons/fa';
import { GoArrowLeft } from 'react-icons/go';
import { IoPersonSharp } from 'react-icons/io5';
import { FaRegCalendarAlt } from 'react-icons/fa';
import DateInput from './DateInput';

import videoUrl from '../Body/Loader.gif'
const LoadingSpinner = () => (
	// <div className='flex justify-center items-center bg-[#515740] h-screen'>
	// 	<div className='animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-[#d5c4b9]'></div>
	// </div>
	<div className='flex justify-center items-center bg-[#515740] h-screen'>
	<img
	  src={videoUrl} 
	  
	/>
  </div>
);

const ScoreDisplay = ({ score }) => (
	<motion.div
		initial={{ opacity: 0, y: 20 }}
		animate={{ opacity: 1, y: 0 }}
		transition={{ duration: 0.5 }}
	>
		<p className='text-[#d5c4b9] px-10 text-9xl lg:text-[160px] font-playfair'>
			{score}
			<span className='text-7xl'>%</span>
		</p>
	</motion.div>
);

const EmojiIcon = ({ score }) => {
	let imageUrl;

	if (score >= 80) {
		imageUrl = 'https://res.cloudinary.com/dzaz9bsnw/image/upload/v1725304460/Website_Slide_Designs_x_Quiz_x_Smiley-04_jamv98.png';
	} else if (score >= 60) {
		imageUrl = 'https://res.cloudinary.com/dzaz9bsnw/image/upload/v1725304461/Website_Slide_Designs_x_Quiz_x_Smiley-01_ernsje.png';
	} else if (score >= 40) {
		imageUrl = 'https://res.cloudinary.com/dzaz9bsnw/image/upload/v1725304460/Website_Slide_Designs_x_Quiz_x_Smiley-03_vi1qmm.png';
	} else {
		imageUrl = 'https://res.cloudinary.com/dzaz9bsnw/image/upload/v1725304461/Website_Slide_Designs_x_Quiz_x_Smiley-02_m8xiym.png';
	}

	return (
		<motion.div
			initial={{ scale: 0 }}
			animate={{ scale: 1 }}
			transition={{ type: 'spring', stiffness: 260, damping: 20 }}
			className='xl:pr-[150px]'
		>
			<img
				src={imageUrl}
				alt="Emoji Icon"
				className='w-[150px] h-[150px] md:w-[300px] md:h-[300px]'
			/>
		</motion.div>
	);
};

const CalmForm = ({ onSubmit, quizResponses }) => {
	const [formData, setFormData] = useState({
		dob: '',
		name: '',
		phone: '',
		email: '',
	});
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;

		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const validateForm = () => {
		let newErrors = {};
		if (!formData.name.trim()) newErrors.name = 'Name is required';
		if (!formData.dob.trim()) newErrors.dob = 'Date of Birth is required';
		if (!formData.phone.trim())
			newErrors.phone = 'Phone number is required';
		else if (!/^\d{10}$/.test(formData.phone))
			newErrors.phone = 'Invalid phone number';
		if (!formData.email.trim()) newErrors.email = 'Email is required';
		else if (!/\S+@\S+\.\S+/.test(formData.email))
			newErrors.email = 'Invalid email address';
		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (validateForm()) {
			setLoading(true);

			const Request = {
				url: process.env.REACT_APP_BACKEND + 'user',
				method: 'POST',
				data: {
					name: formData.name,
					dob: formData.dob,
					phone: formData.phone,
					email: formData.email,
					quizResponses,
					totalScore: quizResponses.reduce(
						(total, score) => total + (score || 0),
						0,
					),
				},
			};

			await Axios(Request);

			setLoading(false);
			onSubmit(formData);
		} else {
			console.log('Form has errors, please correct them');
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<div className='grid grid-cols-1 md:grid-cols-2 gap-11 mb-8'>
				<div className='relative'>
					<div className='border rounded-md border-solid border-[#d5c4b9] md:w-8/12 flex items-center gap-4 h-10 p-3 md:mr-16'>
						<img className='w-[30px]' src='https://res.cloudinary.com/dzaz9bsnw/image/upload/v1725610294/Get_in_Touch_Icons_Name_mq6sbi.png' alt='name' />
						<input
							className='focus:outline-none bg-transparent w-full placeholder-[#8a8775] text-[#d5c4b9]'
							type='text'
							name='name'
							autoComplete='off'
							placeholder='Name'
							value={formData.name}
							onChange={handleChange}
						/>
					</div>
					{errors.name && (
						<p className='text-red-500 text-sm mt-1'>
							{errors.name}
						</p>
					)}
				</div>
				<div className='relative'>
					<div className='appearance border rounded-md border-solid border-[#d5c4b9] md:w-8/12 flex items-center gap-4 h-10 p-3'>
						<img className='w-[30px]' src='https://res.cloudinary.com/dzaz9bsnw/image/upload/v1725610294/Get_in_Touch_Icons_Whatsapp_dl6v0v.png' alt='whatsapp' />
						<input
							className='focus:outline-none bg-transparent w-full placeholder-[#8a8775] text-[#d5c4b9]'
							type='tel'
							name='phone'
							autoComplete='off'
							placeholder='Phone Number'
							value={formData.phone}
							onChange={handleChange}
						/>
					</div>
					{errors.phone && (
						<p className='text-red-500 text-sm mt-1'>
							{errors.phone}
						</p>
					)}
				</div>
				<div className='relative'>
					<div className='border border-solid border-[#d5c4b9] md:w-8/12 rounded-md flex items-center gap-4 h-10 p-3 md:mr-16'>
						<img className='w-[30px]' src='https://res.cloudinary.com/dzaz9bsnw/image/upload/v1725610294/Get_in_Touch_Icons_Calendar_n4lojr.png' alt='dob' />
						{/* <input
							className='focus:outline-none bg-transparent w-full placeholder-[#8a8775] text-[#d5c4b9]'
							type='text'
							name='dob'
							autoComplete='off'
							placeholder='DOB'
							value={formData.dob}
							onChange={handleChange}
							onFocus={(e) => (e.target.type = 'date')}
							onBlur={(e) => {
								if (!e.target.value) e.target.type = 'text';
							}}
						/> */}
						<DateInput value={formData.dob}
							onChange={handleChange}
							name="dob"
							placeholder="DOB" />
					</div>
					{errors.dob && (
						<p className='text-red-500 text-sm mt-1'>
							{errors.dob}
						</p>
					)}
				</div>
				<div className='relative'>
					<div className='border border-solid border-[#d5c4b9] md:w-8/12 rounded-md flex items-center gap-4 h-10 p-3'>
						<img className='w-[30px]' src='https://res.cloudinary.com/dzaz9bsnw/image/upload/v1725610294/Get_in_Touch_Icons_Email_hgdsfk.png' alt='mail' />
						<input
							className='focus:outline-none bg-transparent w-full placeholder-[#8a8775] text-[#d5c4b9]'
							type='email'
							name='email'
							autoComplete='off'
							placeholder='Email'
							value={formData.email}
							onChange={handleChange}
						/>
					</div>
					{errors.email && (
						<p className='text-red-500 text-sm mt-1'>
							{errors.email}
						</p>
					)}
				</div>
			</div>
			<button
				type='submit'
				disabled={loading}
				className={`${loading
					? 'bg-[#515740] text-[#d5c4b9] border border-[#d5c4b9] cursor-not-allowed'
					: 'bg-[#d5c4b9] text-[#515740]'
					} rounded-[8px] text-center w-28 md:w-[120px] h-10 md:h-12 p-4 mt-8 mb-8 font-medium flex items-center justify-center font-urbanist tracking-[2px]`}
			>
				{loading ? (
					<div className='animate-spin rounded-full h-5 w-5 border-b-2 border-[#d5c4b9]'></div>
				) : (
					'Submit'
				)}
			</button>
		</form>
	);
};

const CalmTest = () => {
	const [responses, setResponses] = useState([]);
	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [readyToSubmit, setReadyToSubmit] = useState(false);
	const [questions, setQuestions] = useState([]);
	const [fadeIn, setFadeIn] = useState(false);
	const [loading, setLoading] = useState(true);
	const [testCompleted, setTestCompleted] = useState(false);
	const [finalScore, setFinalScore] = useState(0);
	const [isTransitioning, setIsTransitioning] = useState(false);
	const [showForm, setShowForm] = useState(false);
	const [userInfo, setUserInfo] = useState(null);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	useEffect(() => {
		const Request = {
			url: process.env.REACT_APP_BACKEND + 'quiz',
			method: 'GET',
		};

		(async function () {
			setLoading(true);
			const res = await Axios(Request);
			setQuestions(res.data);
			setLoading(false);
			setTimeout(() => setFadeIn(true), 5);
		})();
	}, []);

	const handleOptionChange = useCallback(
		(score) => {
			if (isTransitioning) return;

			setResponses((prev) => {
				const newResponses = [...prev];
				newResponses[currentQuestion] = score;
				return newResponses;
			});

			setIsTransitioning(true);

			if (currentQuestion < questions.length - 1) {
				setTimeout(() => {
					setCurrentQuestion((prev) => prev + 1);
					setIsTransitioning(false);
				}, 150);
			} else {
				setReadyToSubmit(true);
				setIsTransitioning(false);
			}
		},
		[currentQuestion, questions.length, isTransitioning],
	);

	const handlePrevQuestion = useCallback(() => {
		if (currentQuestion > 0 && !isTransitioning) {
			setIsTransitioning(true);
			setTimeout(() => {
				setCurrentQuestion((prev) => prev - 1);
				setIsTransitioning(false);
			}, 150);
		}
	}, [currentQuestion, isTransitioning]);

	const handleSubmit = useCallback(() => {
		const totalScore = responses.reduce(
			(total, score) => total + (score || 0),
			0,
		);
		const maxScore = questions.length * 4;
		const percentageScore = (totalScore / maxScore) * 100;
		setFinalScore(Math.round(percentageScore));
		setShowForm(true);
	}, [responses, questions.length]);

	const handleFormSubmit = (formData) => {
		setUserInfo(formData);
		setShowForm(false);
		setTestCompleted(true);
	};

	const currentQ = questions[currentQuestion];
	const progressPercentage = ((currentQuestion + 1) / questions.length) * 100;

	if (loading) {
		return <LoadingSpinner />;
	} else if (questions.length === 0) {
		return <div>No Content Found</div>;
	}

	return (
		<div
			className={`bg-[#515740] min-h-screen flex flex-col transition-opacity duration-500 ${fadeIn ? 'opacity-100' : 'opacity-20'
				}`}
		>
			<Header setIsMenuOpen={setIsMenuOpen} />

			{!testCompleted ? (
				<main className='flex-grow py-10 px-6 ml-6 md:ml-0 md:px-12 lg:px-28'>
				{!showForm ? (
					<>
						{currentQuestion === 0 && (
							<h2 className='font-thin text-[#d5c4b9] text-xl md:text-3xl mb-8 font-playfair tracking-wide'>
								Let's Start
							</h2>
						)}
			
						<div className='w-full rounded-lg border border-[#d5c4b9] h-1.5 mb-12'>
							<motion.div
								className='bg-[#d5c4b9] h-full'
								initial={{ width: 0 }}
								animate={{
									width: `${progressPercentage}%`,
								}}
								transition={{ duration: 0.5 }}
							/>
						</div>
			
						<AnimatePresence mode='wait'>
							<motion.div
								className='max-w-7xl'
								key={currentQuestion}
								initial={{ opacity: 0, y: 20 }}
								animate={{ opacity: 1, y: 0 }}
								exit={{ opacity: 0, y: -20 }}
								transition={{ duration: 0.3 }}
							>
								<h3 className='text-[#d5c4b9] font-thin font-playfair text-3xl lg:text-[40px] leading-normal tracking-[3px] mb-8 md:max-w-[900px]'>
									{currentQ.question}
								</h3>
			
								<div className='grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8 md:w-[640px]'>
									{currentQ.options.map(
										(option, optIndex) => (
											<button
												key={optIndex}
												className={`p-4 rounded-lg font-urbanist font-medium text-[11px] md:text-[20px] tracking-[2px] text-xl text-left transition-colors ${
													responses[currentQuestion] === option.score
														? 'bg-[#d5c4b9] text-[#515740]'
														: 'text-[#d5c4b9]'
												} ${
													isTransitioning
														? 'pointer-events-none'
														: ''
												}`}
												onClick={() =>
													handleOptionChange(
														option.score,
													)
												}
												disabled={isTransitioning}
											>
												<div className='flex items-center space-x-3'>
													<div
														className={`w-3 h-3 md:w-4 md:h-4 rounded-full border border-current flex items-center justify-center`}
													>
														{responses[currentQuestion] === option.score && (
															<div
																className={`w-2 h-2 rounded-full ${
																	responses[currentQuestion] === option.score
																		? 'bg-[#515740]'
																		: 'bg-[#d5c4b9]'
																}`}
															/>
														)}
													</div>
													<span>
														{option.answer}
													</span>
												</div>
											</button>
										),
									)}
								</div>
							</motion.div>
						</AnimatePresence>
			
						<div className='flex justify-between items-center mt-8'>
							{currentQuestion > 0 && (
								<motion.button
									className='border border-[#d5c4b9] rounded-full p-4 w-12 h-12 flex items-center justify-center'
									onClick={handlePrevQuestion}
									whileHover={{ scale: 1.1 }}
									whileTap={{ scale: 0.9 }}
									disabled={isTransitioning}
								>
									<GoArrowLeft
										className='w-6 h-6'
										color='#d5c4b9'
									/>
								</motion.button>
							)}
							{currentQuestion > 0 && readyToSubmit && (
								<motion.button
									className='bg-[#d5c4b9] flex justify-center items-center rounded-lg text-[#515740] px-6 py-3 font-urbanist tracking-[2px] font-medium h-[40px] w-[110px] md:h-12 md:w-[120px]'
									onClick={handleSubmit}
									whileHover={{ scale: 1.05 }}
									whileTap={{ scale: 0.95 }}
								>
									Submit
								</motion.button>
							)}
						</div>
						<p className='font-extralight text-[#d5c4b9] mt-10 font-urbanist tracking-wide text-[10px] md:text-sm'>Disclaimer- These questions should not be used for diagnostic purposes.</p>
					</>
				) : (
					<motion.div
						initial={{ opacity: 0, y: 20 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: -20 }}
						transition={{ duration: 0.3 }}
					>
						<h1 className='font-thin text-[#d5c4b9] text-3xl mb-12 md:text-[60px] md:w-[696px] font-playfair leading-snug [word-spacing:6px] tracking-[3px]'>
							Get your Calm score delivered to you!
						</h1>
						<CalmForm
							onSubmit={handleFormSubmit}
							quizResponses={responses}
						/>
			
						<p className='text-[#d5c4b9] font-urbanist text-lg'>
							Your data is safe with us.
						</p>
						<p className='font-light text-[#d5c4b9] mt-5 font-urbanist tracking-wide text-[10px] md:text-sm'>Disclaimer- These questions should not be used for diagnostic purposes.</p>
					</motion.div>
				)}
			</main>
			) : (
				<main className='mt-4 md:flex-grow lg:flex-grow flex items-center md:items-center md:justify-between flex-col-reverse md:flex-row pl-3 md:px-24'>
					<div className='flex flex-col gap-4'>
						<div className='hidden md:block'>
							<motion.p
								initial={{ opacity: 0, y: 20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.5 }}
								className='text-[#d5c4b9] md:text-4xl lg:text-5xl mb-20 font-playfair font-thin tracking-[3px]'
							>
								Your Calmness <br /> Score is <span className='text-[145px] align-text-top'>{finalScore}<span className='text-[90px] align-text-bottom'>%</span></span>
							</motion.p>
							{/* <span className='ml-10'> <ScoreDisplay score={finalScore} /></span> */}

							<motion.p
								initial={{ opacity: 0, y: 20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.5, delay: 0.2 }}
								className='text-[#d5c4b9] text-2xl lg:text-3xl  mt-6 font-thin font-playfair md:max-w-[356px] lg:max-w-[536px] leading-normal tracking-[3px]'
							>
								Give yourself a moment, you’re making progress with each step.
							</motion.p>
							<Link to='/Chakras'>
								<button className="flex items-center justify-center bg-[#d5c4b9] rounded-lg text-[#515740] text-[12px] md:text-[18px] font-medium p-4 mt-14 w-52 h-10 md:w-[320px] md:h-[50px] font-urbanist tracking-wider">
									Let's begin to Re-Align
								</button>
							</Link>
							<p className='font-light text-[#d5c4b9] mt-10 font-urbanist tracking-wide text-[10px] md:text-sm'>Disclaimer- These questions should not be used for diagnostic purposes.</p>
						</div>
						<div className='block md:hidden'>
							<motion.p
								initial={{ opacity: 0, y: 20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.5 }}
								className='text-[#d5c4b9] text-3xl pt-10 pl-10 pr-6 mb-4 font-playfair font-normal leading-none tracking-[3px]'
							>
								Your Calmness <br /> Score is <span className='text-[95px] align-text-top'>{finalScore}<span className='text-[50px] align-text-bottom'>%</span></span>
							</motion.p>
							<motion.p
								initial={{ opacity: 0, y: 20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.5, delay: 0.2 }}
								className='text-[#d5c4b9] font-thin text-xl pl-10 pr-6 mt-6 font-playfair  md:max-w-[356px] lg:max-w-[536px] leading-normal tracking-widest'
							>
								Give yourself a moment, you’re <br/> making progress with each step.
							</motion.p>
							<Link to='/Chakras'>
							<button className="flex items-center justify-center bg-[#d5c4b9] rounded-lg text-[#515740] text-[12px] font-medium  ml-[45px] p-4 mt-14 w-52 h-10 md:w-72 md:h-14 font-urbanist tracking-wider">
								Let's begin to Re-Align
							</button>
							</Link>
							<p className='font-light text-[#d5c4b9] mt-20 font-urbanist tracking-wide px-6 ml-[20px] text-[10px] md:text-sm'>Disclaimer- These questions should not be used for diagnostic purposes.</p>
						</div>

					</div>
					<EmojiIcon score={finalScore} />
				</main>
			)}
		</div>
	);
};

export default CalmTest;